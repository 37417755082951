import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import KeyResources from "../../components/common/KeyResources";
import TopFormWithCenter from "../../components/common/knowledge/Header";
import Cards from "../../components/common/knowledge/Cards";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";

export const blogImg = require("../../assets/img/key-resources/bc_1_2.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_2.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_2.png");

export const tick_icon = require("../../assets/img/tick-icon.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Transform your Slack channel into an AI-driven digital help desk for your employees ",
    url:
      "https://blog.workativ.com/transform-your-business-slack-channel-into-an-it-help-desk-for-your-employees-using-workativ-assistant/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header:
      "Achieve automated resolution of HR and IT support issues with AI and automation.",
    url: "/conversational-ai-platform/automated-resolution",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header:
      "Deflect calls away from the helpdesk by deploying an AI-powered chatbot.",
    url: "https://youtu.be/Gbx-MXB73uU",
    link: "Watch Now",
    alt: "videoImg"
  }
];
export const cardData = [
  {
    href: "#section-1",
    header: "Do general automations help deflect calls away from helpdesk?",
    content:
      "Simple ticket automation models do not reduce the requests to helpdesk or deliver faster assistance.",
    backgroundClass: "card_one"
  },
  {
    href: "#section-2",
    header: "How is Workativ Assistant a call deflection solution?",
    content:
      "With Workativ Assistant, you enable continuity and productivity, day-to-day, at your workplace, by taking care of issues quicker.",
    backgroundClass: "card_two"
  },
  {
    href: "#section-3",
    header: "The benefits of automated resolution",
    content:
      "It’s quicker – no browsing a knowledge base to get the answer resolved.",
    backgroundClass: "card_three"
  }
];

export default function KnowledgePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState();

  return (
    <>
      <TitleAndMetaTags
        title="Call deflection solution for your IT helpdesk with Conversational AI Chatbot | IT Support Automation"
        description="Deflect calls away from IT Helpdesk by providing employees with Chabot and self-resolve issues without having to contact helpdesk for IT support."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Call deflection solution for your IT helpdesk with Conversational AI Chatbot | IT Support Automation"
        ogDescription="Deflect calls away from IT Helpdesk by providing employees with Chabot and self-resolve issues without having to contact helpdesk for IT support."
      />
      <Container>
      <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT">
          <>
            <div className="gen_vs_true">
              <section
                className="wv-banner wv-landing-page-1 --green-bg wv-banner-h320"
                id="scrollToHome"
              >
                <TopFormWithCenter>
                  <TopFormWithCenter.Content>
                    Call Deflection
                  </TopFormWithCenter.Content>
                  <TopFormWithCenter.Header>
                    Move repetitive and simple issues away from help desk.
                  </TopFormWithCenter.Header>

                  <RequestForm isFooterForm={false} />
                </TopFormWithCenter>
               
              </section>
              <section className="wv-section-mv-p pb-200 content_para">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content">
                        Call deflection is one of the best methods to reduce
                        helpdesk costs and help employees self- resolve issues
                        without having to involve the helpdesk support team. A
                        call deflection solution can help companies move
                        repetitive and simple issues away from the helpdesk.
                      </p>
                      <hr className="wv-limited-content wv-section-mv-m mx-auto mt-5 mb-5" />
                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                        According to the HDI survey, call deflection helps
                        offload call volume of about 30-40% to the helpdesk, and
                        this percentage could be higher still.
                      </p>
                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content ">
                        Too many calls to the helpdesk to resolve issues could
                        impact the MTTR because of a pile- up of requests, which
                        would be a setback to agent productivity.
                      </p>
                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                        To address a multitude of requests, the resort is to
                        automate tasks and here are some of the general
                        automations for helpdesk in the market currently:
                      </p>
                      <ul className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content ">
                        <li>Email to ticket conversions</li>
                        <li>Ticket routing</li>
                        <li>Ticket categorisation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <Cards cards={cardData} />
              <section className="bg_section_knowledge self_service_an">
                <div className="container">
                  <div className="row">
                    <div className="col pr-0 pl-0  -call-reflection">
                      <div className="">
                        <div className="main-slider-left">
                          <h1>
                            Call deflection helps offload call volume of about
                            20-30% to the helpdesk.
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="wv-main-content wv-section-mv-p0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 --sh-sm mb-4">
                      <div className="dropdown wv-dropdown">
                        <button
                          className={`link ${isOpen ? "active" : ""}`}
                          onClick={() => setOpen(!isOpen)}
                        >
                          Explore this articles
                        </button>
                        <div
                          id="myDropdown"
                          className={`accordion-item submenu dropdown-content ${
                            !isOpen ? "collapsed" : ""
                          }`}
                        >
                          <li>
                            <AnchorLink
                              offset={300}
                              className="url_manipulation"
                              href="#section-1"
                            >
                              Do these automations really help deflect calls?
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={290}
                              className="url_manipulation"
                              href="#section-2"
                            >
                              The benefits of automated resolution
                            </AnchorLink>
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 --hd-sm">
                      <aside className="wv-sidenav max-width-255" id="myHeader">
                        <p className="wv-sm-heading">Explore this article</p>
                        <ul className="mb-5">
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-1"
                              className={
                                isActive ===
                                " Do these automations really help deflect calls?"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  " Do these automations really help deflect calls?"
                                );
                              }}
                            >
                              {" "}
                              Do these automations really help deflect calls?
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-2"
                              className={
                                isActive ===
                                "The benefits of automated resolution"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  "The benefits of automated resolution"
                                );
                              }}
                            >
                              {" "}
                              The benefits of automated resolution
                            </AnchorLink>
                          </li>
                        </ul>
                        <AnchorLink offset={200} href="#scrollToHome">
                          {" "}
                          Back to top ↑
                        </AnchorLink>
                      </aside>
                    </div>
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="wv-content-area">
                        <section className="wv-content-section">
                          <div id="section-1">
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">
                                  But do these automations really help deflect
                                  calls away from the helpdesk?
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                  Simple ticket automation models do not reduce
                                  the requests to the helpdesk or deliver faster
                                  assistance. Even simple L1 issues take time to
                                  be resolved because of the enormous ticket
                                  queue, leading to frustrated employees and a
                                  low productivity environment.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                  If you are looking to reduce a chunk of tasks
                                  for the IT Helpdesk team, your support needs
                                  to be equipped with a call deflection
                                  solution.
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className=" wv-content-section">
                          <div id="section-2">
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">
                                  How is Workativ Assistant a call deflection
                                  solution?
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  With Workativ Assistant’s automated resolution
                                  system, you have a smart call deflection
                                  model. You enable your employees to
                                  autonomously resolve L1 issues by conversing
                                  with a virtual assistant that gets the job
                                  done instead of waiting for the helpdesk agent
                                  to resolve and close the issue.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                  With Workativ Assistant, you enable continuity
                                  and productivity, day-to-day, at your
                                  workplace, by taking care of issues quicker.
                                  Workativ Assistant provides a conversational
                                  virtual assistant that allows employees to
                                  resolve their issues instantly, anytime,
                                  thereby creating a better user experience
                                  through self-service.
                                </p>
                              </div>
                            </div>
                          </div>
                          <section className="benefits_knowledge">
                            <div className="container">
                              <div className="row">
                                <div className="">
                                  <div className="">
                                    <section
                                      className="cards_without_bg"
                                      id="section-3"
                                    >
                                      <h4 className="header_h4">
                                        The benefits of automated resolution
                                      </h4>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            It’s quicker
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            No browsing a knowledge base to get
                                            the answer resolved.
                                          </p>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            No human intervention
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            The assistant is designed with
                                            automation capabilities to resolve
                                            issues instantly, thereby deflecting
                                            calls away from the helpdesk.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row mb-4">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Reduced wait times
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            Employees won’t be checking the
                                            status of their tickets as much as
                                            they used to because of the
                                            innovative self-service you provide.
                                            You’ll find that tickets reach a
                                            close faster.
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
          <KeyResources cards={resoucesData} />
          <RequestForm isFooterForm={true} />
        </Layout>
        {/* <OnScrollPopup /> */}
      </Container>
    </>
  );
}
